$font-lato: Lato, sans-serif;
$font-montserrat: Montserrat, sans-serif;

@font-face {
  font-family: Montserrat;
  font-style: italic;
  src: url(../assets/fonts/montserrat/Montserrat-Italic-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  src: url(../assets/fonts/montserrat/Montserrat-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  src: url(../assets/fonts/lato/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  src: url(../assets/fonts/lato/Lato-Italic.ttf) format("truetype");
}
@font-face {
  font-family: Lato;
  font-weight: bold;
  src: url(../assets/fonts/lato/Lato-Bold.ttf) format("truetype");
}
@font-face {
  font-family: Lato;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/lato/Lato-BoldItalic.ttf) format("truetype");
}
